/**
 * Add fn as callback to listener on window load event.
 * If the load event has already happened execute the callback right away and remove the load event listener
 * @param  {Function} fn
 */
const windowIsLoaded = (fn) => {

  // Add load event listener that calls fn()
  window.addEventListener('load', fn);

  // If the load event is missed, and the document readyState is complete, call fn()
  if (document.readyState === 'complete' ) {
    // window.removeEventListener('load', fn);
    fn();
  }

};

/**
 * Add fn as callback to listener on window DOMContentLoaded event.
 * If the DOMContentLoaded event has already happened execute the callback right away
 * @param  {Function} fn
 */
const windowIsReady = (fn) => {

  // Add DOMContentLoaded event listener that calls fn()
  window.addEventListener('DOMContentLoaded', fn);

  // If the DOMContentLoaded event has already happened, and the document readyState is complete, call fn()
  if (document.readyState === 'interactive' || document.readyState === 'complete' ) {
    // window.removeEventListener('DOMContentLoaded', fn);
    fn();
  }
};

/**
 * Return a Promise that resolves a callback when all lazysize images are loaded
 * @param {HTMLElement} container An element that may contain lazysizes images
 * @param {function} callback A callback to run when all images are loaded
 * @returns Promise
 */
const lazySizesPromise = (container, callback) => {

  // Check if there are any images to be loaded or currently loading
  let hasLazyImages = container.querySelectorAll('.lazyload, .lazyloading').length;

  return new Promise((resolve) => {

    if(hasLazyImages > 0) {

      let loaded = 0;

      // Force lazyload images to load
      container.querySelectorAll('.lazyload').forEach(img => img.classList.add('lazypreload'));

      // Listen for lazyloaded event on images
      container.querySelectorAll('.lazyload, .lazyloading').forEach(img => {

        img.addEventListener('lazyloaded', () => {

          // Increase the loaded counter
          loaded++;

          // When all images are loaded, resolve the callback
          if(loaded >= hasLazyImages) {

            setTimeout(() => {

              resolve(callback());

            }, 1000);


          }
        });
      });

    } else {

      // Resolve the callback right away when there are no images that needed loading
      resolve(callback());

    }

  });
}

/**
 * Set height css variable on element and update on resize
 * @param  {HTMLElement} element
 */
const trackElementHeight = (element) => {

  /**
   * Set a css variable for the panel height based on its children height
   * @param  {HTMLElement} element
   */
  const setElementHeight = (element) => {

    element.style.setProperty('--element-height', `${element.scrollHeight}px`);

  }

  windowIsLoaded(() => {

    // Calculate the height the element occupies
    setElementHeight(element);
    window.addEventListener('resize', setElementHeight.bind(null, element));

  });

};

/**
 * Helper to find out if the device is a touch device
 * @return {Boolean}
 */
const isTouchDevice = () => {

  return (('ontouchstart' in window)
    || (navigator.MaxTouchPoints > 0)
    || (navigator.msMaxTouchPoints > 0));

};

/**
 * Get a random integer between 2000 and 6000
 * @return {int}
 */
const getRandomInteger = (min=0, max=100) => {

  return Math.floor(Math.random() * (max - min + 1)) + min;

};

/**
 * Check if an element is in the viewport regarding an offset
 */
const isPastViewportBottom = (element, offsetY=0) => {

  const rect = element.getBoundingClientRect();

  return (
      rect.top <= offsetY
  );
};


  // Function to detect if the browser is Safari
  const isSafari = () => {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  function isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }

export default {
  windowIsLoaded: windowIsLoaded,
  windowIsReady: windowIsReady,
  lazySizesPromise: lazySizesPromise,
  trackElementHeight: trackElementHeight,
  isTouchDevice: isTouchDevice,
  getRandomInteger: getRandomInteger,
  isPastViewportBottom: isPastViewportBottom,
  isSafari: isSafari,
  isIOS: isIOS
}
